import React from 'react'

const PublicationsList = () => (
  <div>
    <h2 className="minor"><a href="https://patents.google.com/?inventor=Vishal+Berry" target="_blank" rel="noopener noreferrer">Patents</a></h2>

    <p><a href="https://patents.google.com/patent/US10142429B2/en" target="_blank" rel="noopener noreferrer">Web presence management system</a>
    <br />US10142429B2  •  Nov 27, 2018</p>

    <p><a href="https://patents.google.com/patent/US20160098380A1/en" target="_blank" rel="noopener noreferrer">Mapping of content on a web site to product functionality</a>
    <br />US20160098380A1  •  Oct 7, 2015</p>

    <p><a href="https://patents.google.com/patent/US8630992B1/en" target="_blank" rel="noopener noreferrer">URL rank variability determination</a>
    <br />US8630992B1  •  Jan 14, 2014</p>

    <p><a href="https://patents.google.com/patent/US8606769B1/en" target="_blank" rel="noopener noreferrer">Ranking a URL based on a location in a search engine results page</a>
    <br />US8606769B1  •  Dec 10, 2013</p>

    <p><a href="https://patents.google.com/patent/US20150019519A1/en" target="_blank" rel="noopener noreferrer">Location based search engine position</a>
    <br />US20150019519A1  •  Jul 12, 2013</p>

    <p><a href="https://patents.google.com/patent/US20120284285A1/en" target="_blank" rel="noopener noreferrer">Keyword filter</a>
    <br />US20120284285A1  •  Nov 8, 2012</p>


    <h2 className="minor"><a href="https://www.ncbi.nlm.nih.gov/sites/entrez?db=pubmed&amp;term=%22Berry,+Vishal%22" target="_blank" rel="noopener noreferrer">Publications</a></h2>

    <p><a href="https://onlinelibrary.wiley.com/journal/15524930" target="_blank" rel="noopener noreferrer">Cytometry</a> • Issue 69A, December 20, 2005
    <br /><a href="https://www.ncbi.nlm.nih.gov/pubmed/16369935?dopt=Abstract" target="_blank" rel="noopener noreferrer">Individualizing combination of two antiproliferative immunosuppressants...</a></p>

    <p><a href="https://www.bioscience.org/" target="_blank" rel="noopener noreferrer">Frontiers in Bioscience</a> • Issue 9, May 1, 2004
    <br /><a href="https://www.ncbi.nlm.nih.gov/pubmed/14977538?dopt=Abstract" target="_blank" rel="noopener noreferrer">Multiparametric Effect: Concentration Analyses.</a></p>

  </div>
)

export default PublicationsList
