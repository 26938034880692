import React from 'react'

const WorkList = () => (
  <div>
  <h2 className="major">Work</h2>
  <p>2022-2024: Curating community at <a href="https://canteenlabs.com/" target="_blank" rel="noopener noreferrer">Canteen Labs</a></p>
  <p>2020-2022: Connecting classrooms at <a href="https://empatico.buildersmovement.org/" target="_blank" rel="noopener noreferrer">Empatico</a></p>
  <p>2019-2022: Startup consulting and M&A advisory</p>
  <p>2008-2019: Product at <a href="https://www.conductor.com/" target="_blank" rel="noopener noreferrer">Conductor</a> and <a href="https://www.wework.com/" target="_blank" rel="noopener noreferrer">WeWork</a></p>
  <p>2007-2008: Data quality at <a href="https://www.opentext.com/products-and-solutions/products/customer-experience-management/opentext-marketing-optimization/opentext-optimost" target="_blank" rel="noopener noreferrer">Optimost</a> and <a href="https://www.linkedin.com/company/interwoven/" target="_blank" rel="noopener noreferrer">Interwoven</a></p>
  <p>2006-2007: Search quality at <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">Google</a></p>
  <p>2002-2006: Biomed research at <a href="https://www.upmc.com/" target="_blank" rel="noopener noreferrer">UPMC</a> and <a href="https://www.cincinnatichildrens.org/" target="_blank" rel="noopener noreferrer">CCHMC</a></p>
  <p>2000-2004: Engineering at <a href="https://www.cmu.edu/bme/" target="_blank" rel="noopener noreferrer">Carnegie Mellon</a></p>
  </div>
)

export default WorkList
